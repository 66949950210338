import createSagaMiddleware from "redux-saga";
import {applyMiddleware, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'
import { composeWithDevTools } from 'redux-devtools-extension';

const createBrowserHistory = require('history').createBrowserHistory;


// Make history a singleton instance
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

// Remove duplicate routerMiddleware from middlewares array since it's already included in applyMiddleware
const middlewares = [thunk, sagaMiddleware];

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        ...middlewares
      ),
    ),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
