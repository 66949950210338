import { Auth } from 'aws-amplify';

export const GetGroupsOfUser = async (username) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        console.log("groups===========================", groups)
        return groups;
    } catch (error) {
        console.error('Error getting user groups:', error);
        throw error;
    }
};